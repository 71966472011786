interface GTMDataLayer {
    push: (payload: any) => void
}

export function getGoogleTagManagerDataLayer(): GTMDataLayer | undefined {
    const dataLayer: GTMDataLayer = (window as any).dataLayer

    if (dataLayer == undefined) {
        console.warn('Could not access GTM datalayer instance')
        return undefined
    }

    return dataLayer
}

export function sendGoogleTagManagerEvent(eventName: string, conversionValue: any) {
    const dataLayer = getGoogleTagManagerDataLayer()
    if (dataLayer == undefined) return

    dataLayer.push({ event: eventName, conversionValue: conversionValue })
}
